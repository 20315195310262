import {
  Typography
} from '@material-ui/core';

const SituacaoAssinaturaOutroStatus = (props) => {
  const param = props;
  return (
    <Typography color="black" variant="h3">
      {param.user.descricao}
    </Typography>
  );
};

export default SituacaoAssinaturaOutroStatus;
