import SituacaoAssinaturaPendente from './SituacaoAssinaturaPendente';
import SituacaoAssinaturaAtiva from './SituacaoAssinaturaAtiva';
import SituacaoAssinaturaCancelada from './SituacaoAssinaturaCancelada';
import SituacaoAssinaturaIncompleta from './SituacaoAssinaturaIncompleta';
import SituacaoAssinaturaOutroStatus from './SituacaoAssinaturaOutroStatus';

function SituacaoAssinatura(props) {
  const param = props;
  const statusAssinatura = {
    iniciado: { id: 0, status_original: 'INITIATED', descricao: 'INICIADO' },
    pendente: { id: 1, status_original: 'PENDING', descricao: 'PENDENTE' },
    ativo: { id: 2, status_original: 'ACTIVE', descricao: 'ATIVO' },
    payment_change: { id: 3, status_original: 'PAYMENT_METHOD_CHANGE', descricao: 'PAYMENT_METHOD_CHANGE' },
    suspenso: { id: 4, status_original: 'SUSPENDED', descricao: 'SUSPENSO' },
    cancelado: { id: 5, status_original: 'CANCELLED', descricao: 'CANCELADO' },
    cancelado_cliente: { id: 6, status_original: 'CANCELLED_BY_RECEIVER', descricao: 'CANCELADO' },
    cancelado_vendedor: { id: 7, status_original: 'CANCELLED_BY_SENDER', descricao: 'CANCELADO' },
    expirado: { id: 8, status_original: 'EXPIRED', descricao: 'EXPIRADO' }
  };
  console.log(param);
  if (param.user.status_assinatura === statusAssinatura.ativo.id) {
    return <SituacaoAssinaturaAtiva user={param.user} />;
  }
  if (param.user.status_assinatura === statusAssinatura.cancelado.id || param.user.status_assinatura === statusAssinatura.cancelado_cliente.id || param.user.status_assinatura === statusAssinatura.cancelado_vendedor.id) {
    return <SituacaoAssinaturaCancelada />;
  }
  if (param.user.status_assinatura === statusAssinatura.iniciado.id || param.user.code === null || param.user.code === '' || param.user.code === undefined || param.user.code === 'null') {
    return <SituacaoAssinaturaIncompleta user={param.user} />;
  }
  if (param.user.status_assinatura === statusAssinatura.pendente.id) {
    return <SituacaoAssinaturaPendente />;
  }

  let status;
  if (param.user.status_assinatura === statusAssinatura.payment_change.id) { status = statusAssinatura.payment_change; }
  if (param.user.status_assinatura === statusAssinatura.expirado.id) { status = statusAssinatura.expirado; }
  if (param.user.status_assinatura === statusAssinatura.suspenso.id) { status = statusAssinatura.suspenso; }

  return <SituacaoAssinaturaOutroStatus user={status} />;
}

export default SituacaoAssinatura;
