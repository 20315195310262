import {
  Typography
} from '@material-ui/core';

function SituacaoAssinaturaPendente() {
  return (
    <Typography color="black" variant="h3">
      PENDENTE
    </Typography>
  );
}

export default SituacaoAssinaturaPendente;
