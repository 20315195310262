import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import {
  Box,
  Grid,
  Button,
  TextField,
  RadioGroup,
  Radio,
  FormLabel,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Typography
} from '@material-ui/core';
import moment from 'moment';
import SituacaoAssinatura from 'src/components/customer/SituacaoAssinatura';
import properties from 'src/utils/properties';

const Account = () => {
  const [estados, setEstados] = useState([]);
  const [usuario, setUsuario] = useState([]);
  const [status, setStatus] = useState([]);
  const [load, setLoad] = useState([]);
  const loading = { type: 'bars', color: '#DAA520' };
  let idpessoa;
  let token;
  let urlGetUser;
  const config = { headers: { 'x-access-token': '' } };

  function consultaPessoa() {
    urlGetUser = `${properties.server_host}/getuser/${idpessoa}`;
    axios.get(urlGetUser, config).then((response) => {
      setUsuario(response.data.pessoa);
    });
  }

  useEffect(() => {
    token = localStorage.getItem('TOKEN_KEY');
    config.headers['x-access-token'] = token;
    idpessoa = localStorage.getItem('PESSOA');
    console.log(`token: ${token}`);
    console.log(`idpessoa: ${idpessoa}`);
    consultaPessoa();
  }, []);

  useEffect(() => {
    const urlUf = `${properties.server_host}/estados`;
    axios.get(urlUf).then((response) => {
      setEstados(response.data);
    });
  }, []);

  const validationSchemaConst = Yup.object().shape({
    txtNome: Yup.string().max(50, 'Tamanho máximo do Nome é de 50 caracteres').required('Nome é Obrigatório'),
    dataNascimento: Yup.date().required('Data Nascimento é obrigatório'),
    txtCPF: Yup.string().max(11).required('CPF é obrigatório').max(11, 'Tamanho máximo de 11 dígitos. Não inserir ponto (.), traço (-) ou espaço'),
    email: Yup.string().max(60, 'Tamanho máximo do email é de 60 caracteres').email('Email incorreto').max(255)
      .required('Email é obrigatório'),
    numDdd: Yup.string().max(2, 'Apenas 2 dígitos. Exemplo: 48'),
    txtCelular: Yup.string().min(7).max(9, 'Tamanho máximo de 9 dígitos.  Não inserir ponto (.), traço (-) ou espaço'),
    txtRua: Yup.string().max(80).required('Rua é obrigatório'),
    txtNumero: Yup.string().max(20),
    txtComplemento: Yup.string().max(40),
    txtBairro: Yup.string().max(60),
    txtCidade: Yup.string().max(60).required('Cidade é Obrigatório'),
    txtCEP: Yup.string().max(8, 'Máximo 8 caracteres. Não inserir ponto (.), traço (-) ou espaço').required('CEP é Obrigatório'),
    cmbUF: Yup.string().required('Estado é Obrigatório'),
    password: Yup.string().min(5, 'Senha deve ter no mínimo 5 dígitos').required('Senha é obrigatório'),
    passwordRepeat: Yup.string().required('password is required').oneOf([Yup.ref('password'), null], 'Senha não confere'),
    sexo: Yup.string().required('Informe o sexo')
  });

  const formik = useFormik({
    initialValues: {
      txtNome: usuario.nome,
      dataNascimento: moment(new Date(usuario.nascimento)).format('yyyy-MM-DD'),
      sexo: usuario.sexo,
      txtCPF: usuario.cpf,
      numDdd: usuario.ddd,
      email: usuario.email,
      txtCelular: usuario.celular,
      txtRua: usuario.rua,
      txtNumero: usuario.numero,
      txtComplemento: usuario.complemento,
      txtBairro: usuario.bairro,
      txtCidade: usuario.cidade,
      txtCEP: usuario.cep,
      cmbUF: usuario.uf,
      password: '',
      passwordRepeat: '',
    },
    validationSchema: validationSchemaConst,
    onSubmit: (values) => {
      console.log('entrou submit');
      setLoad({
        showLoader: true
      });
      console.log(values);
      const param = values;
      param.idpessoa = usuario.id_pessoa;
      const urlUpdate = `${properties.server_host}/update`;
      token = localStorage.getItem('TOKEN_KEY');
      config.headers['x-access-token'] = token;
      console.log(config);
      axios.post(urlUpdate, { param }, config).then((response) => {
        console.log(response);
        if (response.status === 200) {
          if (response.data.COD === 0) {
            if (response.data.message) {
              setStatus({ success: response.data.message });
            } else {
              setStatus({ success: 'Erro Atualizando Dados. Tente novamente mais tarde' });
            }
            setLoad({
              showLoader: false
            });
          } else if (response.data.COD === 10) {
            setStatus({ success: 'Cadastro Atualizado com Sucesso!' });
            setLoad({
              showLoader: false
            });
          }
        }
      });
    },
    enableReinitialize: true
  });

  return (
    <>
      <Helmet>
        <title>Conta Apoiador</title>
      </Helmet>
      <Card>
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <Typography
                color="textPrimary"
                variant="h3"
              >
                Assinatura
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <Typography
                color="textSecondary"
                gutterBottom
                variant="h4"
              >
                {usuario.descricao}
                :
                R$
                {usuario.valor}
              </Typography>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="h4"
              >
                Situação:
              </Typography>
              <SituacaoAssinatura user={usuario} />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <Typography
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                Não é possível editar os dados da Assinatura, para alterar o plano entre em contato conosco pelo contato@institutosantoagostinho.org
              </Typography>

            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <form onSubmit={formik.handleSubmit}>
        <Card>
          <CardHeader
            subheader="Alteração dos dados pessoais"
            title="Dados Pessoais"
          />
          <Typography
            color="red"
            align="center"
            variant="h4"
          >
            {status ? status.success : ''}
          </Typography>
          <Divider />
          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={12}
                xs={12}
              >
                <FormLabel component="legend">Nome</FormLabel>
                <TextField
                  error={Boolean(formik.touched.txtNome && formik.errors.txtNome)}
                  fullWidth
                  helperText={formik.touched.txtNome && formik.errors.txtNome}
                  margin="normal"
                  name="txtNome"
                  disabled
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.txtNome}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <FormLabel component="legend">Data Nascimento</FormLabel>
                <TextField
                  error={Boolean(formik.touched.dataNascimento && formik.errors.dataNascimento)}
                  fullWidth
                  helperText={formik.touched.dataNascimento && formik.errors.dataNascimento}
                  margin="normal"
                  name="dataNascimento"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  required
                  type="date"
                  value={formik.values.dataNascimento}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    ml: -1
                  }}
                >
                  <FormLabel component="fieldset">
                    <FormLabel component="legend">Sexo</FormLabel>
                    <RadioGroup
                      error={Boolean(formik.errors.sexo)}
                      helperText={formik.touched.sexo && formik.errors.sexo}
                      margin="normal"
                      name="sexo"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      required
                      radio
                      variant="outlined"
                    >
                      <Grid
                        container
                        spacing={3}
                      >
                        <Grid
                          item
                          md={6}
                          xs={12}
                        >
                          <FormLabel component="legend">Masculino</FormLabel>
                          <Radio
                            color="primary"
                            checked={formik.values.sexo === 'M'}
                            value="M"
                          />
                        </Grid>
                        <Grid
                          item
                          md={6}
                          xs={12}
                        >
                          <FormLabel component="legend">Feminino</FormLabel>
                          <Radio
                            color="primary"
                            checked={formik.values.sexo === 'F'}
                            value="F"
                          />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </FormLabel>
                </Box>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <FormLabel component="legend">CPF</FormLabel>
                <TextField
                  error={Boolean(formik.touched.txtCPF && formik.errors.txtCPF)}
                  fullWidth
                  helperText={formik.touched.txtCPF && formik.errors.txtCPF}
                  margin="normal"
                  name="txtCPF"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  disabled
                  value={formik.values.txtCPF}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <FormLabel component="legend">Email</FormLabel>
                <TextField
                  error={Boolean(formik.touched.email && formik.errors.email)}
                  fullWidth
                  helperText={formik.touched.email && formik.errors.email}
                  margin="normal"
                  name="email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  required
                  type="email"
                  value={formik.values.email}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <FormLabel component="legend">DDD</FormLabel>
                <TextField
                  error={Boolean(formik.touched.numDdd && formik.errors.numDdd)}
                  fullWidth
                  helperText={formik.touched.numDdd && formik.errors.numDdd}
                  margin="normal"
                  name="numDdd"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.numDdd}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <FormLabel component="legend">Celular/Whatsapp</FormLabel>
                <TextField
                  error={Boolean(formik.touched.txtCelular && formik.errors.txtCelular)}
                  fullWidth
                  helperText={formik.touched.txtCelular && formik.errors.txtCelular}
                  margin="normal"
                  name="txtCelular"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="tel"
                  value={formik.values.txtCelular}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <FormLabel component="legend">Rua</FormLabel>
                <TextField
                  error={Boolean(formik.touched.txtRua && formik.errors.txtRua)}
                  fullWidth
                  helperText={formik.touched.txtRua && formik.errors.txtRua}
                  margin="normal"
                  name="txtRua"
                  required
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.txtRua}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <FormLabel component="legend">Número</FormLabel>
                <TextField
                  error={Boolean(formik.touched.txtNumero && formik.errors.txtNumero)}
                  fullWidth
                  helperText={formik.touched.txtNumero && formik.errors.txtNumero}
                  margin="normal"
                  name="txtNumero"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.txtNumero}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <FormLabel component="legend">Complemento</FormLabel>
                <TextField
                  error={Boolean(formik.touched.txtComplemento && formik.errors.txtComplemento)}
                  fullWidth
                  helperText={formik.touched.txtComplemento && formik.errors.txtComplemento}
                  margin="normal"
                  name="txtComplemento"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.txtComplemento}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <FormLabel component="legend">Bairro</FormLabel>
                <TextField
                  error={Boolean(formik.touched.txtBairro && formik.errors.txtBairro)}
                  fullWidth
                  helperText={formik.touched.txtBairro && formik.errors.txtBairro}
                  margin="normal"
                  name="txtBairro"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.txtBairro}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <FormLabel component="legend">Cidade</FormLabel>
                <TextField
                  error={Boolean(formik.touched.txtCidade && formik.errors.txtCidade)}
                  fullWidth
                  helperText={formik.touched.txtCidade && formik.errors.txtCidade}
                  margin="normal"
                  name="txtCidade"
                  required
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.txtCidade}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <FormLabel component="legend">CEP</FormLabel>
                <TextField
                  error={Boolean(formik.touched.txtCEP && formik.errors.txtCEP)}
                  fullWidth
                  helperText={formik.touched.txtCEP && formik.errors.txtCEP}
                  margin="normal"
                  name="txtCEP"
                  required
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.txtCEP}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
              >
                <FormLabel component="legend">Estado</FormLabel>
                <TextField
                  error={Boolean(formik.touched.cmbUF && formik.errors.cmbUF)}
                  fullWidth
                  helperText={formik.touched.cmbUF && formik.errors.cmbUF}
                  margin="normal"
                  name="cmbUF"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={formik.values.cmbUF}
                  variant="outlined"
                >
                  <option value=""> </option>
                  {estados.map((estado) => (<option key={estado.sigla} value={estado.sigla}>{estado.nome}</option>))}
                </TextField>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <FormLabel component="legend">Senha</FormLabel>
                <TextField
                  error={Boolean(formik.touched.password && formik.errors.password)}
                  fullWidth
                  helperText={formik.touched.password && formik.errors.password}
                  margin="normal"
                  name="password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  required
                  type="password"
                  value={formik.values.password}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <FormLabel component="legend">Repetir Senha</FormLabel>
                <TextField
                  error={Boolean(formik.touched.passwordRepeat && formik.errors.passwordRepeat)}
                  fullWidth
                  helperText={formik.touched.passwordRepeat && formik.errors.passwordRepeat}
                  margin="normal"
                  name="passwordRepeat"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  required
                  type="password"
                  value={formik.values.passwordRepeat}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              .
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <Box
                sx={{
                  py: 2,
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                {load.showLoader && (
                  <ReactLoading type={loading.type} color={loading.color} />
                )}
                <Button
                  color="primary"
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Alterar Dados
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </form>
    </>
  );
};

export default Account;
