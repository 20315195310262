import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import axios from 'axios';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography
} from '@material-ui/core';
import properties from 'src/utils/properties';

const Login = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState([]);
  const [load, setLoad] = useState([]);
  const loading = { type: 'bars', color: '#DAA520' };
  const { state } = useLocation();

  useEffect(() => {
    console.log(state);
    if (state) {
      const msg = state.message;
      setStatus({ success: msg });
    }
  }, []);

  const validSchema = Yup.object().shape({
    email: Yup.string().email('Email incorreto').max(255).required('Email é obrigatório'),
    password: Yup.string().max(255).required('Senha é obrigatória')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: validSchema,
    onSubmit: (values) => {
      setLoad({
        showLoader: true
      });
      const user = {
        email: values.email,
        password: values.password
      };
      const urlLogin = `${properties.server_host}/login`;
      axios.post(urlLogin, user).then((resposta) => {
        console.log(resposta.data);
        if (resposta.data.auth === false) {
          setStatus({ success: 'Tempo de Login Expirado' });
          setLoad({
            showLoader: false
          });
        } else {
          setLoad({
            showLoader: false
          });
          const { pessoa } = resposta.data;
          const { token } = resposta.data;
          localStorage.setItem('TOKEN_KEY', token);
          localStorage.setItem('PESSOA', pessoa);
          navigate('/app/account/', { state: { idpessoa: pessoa } });
        }
      }).catch((erro) => {
        console.error('entrou erro', erro);
        if (erro.response.status === 500) {
          setStatus({ success: erro.response.data.message });
          setLoad({
            showLoader: false
          });
        } else {
          setStatus({ success: 'Erro Autenticando Usuário' });
          setLoad({
            showLoader: false
          });
        }
      });
    }
  });

  return (
    <>
      <Helmet>
        <title>Login de Apoiador</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              py: 2,
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              marginBottom: '50px'
            }}
          >
            <Typography
              color="textPrimary"
              variant="h1"
            >
              APOIE o
            </Typography>
            <img
              alt="Icon"
              src="/static/logo400px.png"
              height="70%"
              width="70%"
            />
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ mb: 3 }}>
              <Typography
                color="textPrimary"
                variant="h2"
              >
                Login
              </Typography>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                Acesse sua conta de Apoiador
              </Typography>
              <Typography
                color="red"
                align="center"
                variant="h4"
              >
                {status ? status.success : ''}
              </Typography>
            </Box>
            <TextField
              error={Boolean(formik.touched.email && formik.errors.email)}
              fullWidth
              helperText={formik.touched.email && formik.errors.email}
              label="Email"
              margin="normal"
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="email"
              value={formik.values.email}
              variant="outlined"
            />
            <TextField
              error={Boolean(formik.touched.password && formik.errors.password)}
              fullWidth
              helperText={formik.touched.password && formik.errors.password}
              label="Senha"
              margin="normal"
              name="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="password"
              value={formik.values.password}
              variant="outlined"
            />
            <Box
              sx={{
                py: 2,
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              {load.showLoader && (
                <ReactLoading type={loading.type} color={loading.color} />
              )}
              <Button
                color="primary"
                size="large"
                type="submit"
                variant="contained"
              >
                ENTRAR
              </Button>
            </Box>
            <Typography
              color="textSecondary"
              variant="body1"
            >
              Seja apoiador do Instituto Santo Agostinho
              {' '}
              <Link
                component={RouterLink}
                to="/register"
                variant="h6"
              >
                Clique aqui
              </Link>
            </Typography>
            <Typography
              color="textSecondary"
              variant="body1"
            >
              Esqueceu a Senha ?
              {' '}
              <Link
                component={RouterLink}
                to="/recovery"
                variant="h6"
              >
                Clique aqui
              </Link>
            </Typography>
          </form>
        </Container>
      </Box>
    </>
  );
};

export default Login;
