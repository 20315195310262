import React, { useState } from 'react';
import ReactLoading from 'react-loading';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import axios from 'axios';
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import properties from 'src/utils/properties';

const CreditCard = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [load, setLoad] = useState([]);
  const [status, setStatus] = useState([]);
  const { idassinatura } = state;
  const card = {};
  let sessionid = null;
  const loading = { type: 'bars', color: '#DAA520' };

  const validationSchemaConst = Yup.object().shape({
    cardHolderName: Yup.string().max(50).required('Nome é Obrigatório'),
    cardNumber: Yup.string().required('Número do Cartão é obrigatório'),
    cardExpirationMonth: Yup.string().required('Mês é Obrigatório'),
    cardExpirationYear: Yup.string().required('Ano é Obrigatório'),
    cardCvv: Yup.string().required('Código é Obrigatório'),
    cardDtNasc: Yup.date().required('Data Nascimento do Titular é obrigatório'),
    cardCpf: Yup.string().required('CPF do Titular é Obrigatório'),
    cardDdd: Yup.string().required('DDD do Titular é Obrigatório').max(2, 'Apenas 2 dígitos. Exemplo: 48'),
    cardCelular: Yup.string().required('Número do Fone do Titular é Obrigatório').min(7).max(9, 'Tamanho máximo de 9 dígitos.  Não inserir ponto (.), traço (-) ou espaço')
  });

  const formik = useFormik({
    initialValues: {
      cardHolderName: '',
      cardNumber: '',
      cardExpirationMonth: '',
      cardExpirationYear: '',
      cardCvv: '',
      cardDtNasc: '',
      cardCpf: '',
      cardDdd: '',
      cardCelular: ''
    },
    validationSchema: validationSchemaConst,
    onSubmit: (values) => {
      console.log('entrou submit CC');
      console.log(values);
      setLoad({
        showLoader: true
      });
      const binCard = values.cardNumber.substring(0, 6);
      console.log(binCard);
      const urlGetSession = `${properties.server_host}/getsession`;
      axios.post(urlGetSession).then((resp) => {
        console.log(resp.data.sessionid);
        sessionid = resp.data.sessionid;
        window.PagSeguroDirectPayment.setSessionId(sessionid);

        window.PagSeguroDirectPayment.getBrand({
          cardBin: values.cardNumber.substr(0, 6),
          success: (response) => {
            console.log(response.brand.name);

            window.PagSeguroDirectPayment.createCardToken({
              cardNumber: values.cardNumber,
              brand: response.brand.name,
              cvv: values.cardCvv,
              expirationMonth: values.cardExpirationMonth,
              expirationYear: values.cardExpirationYear,
              success: (responseCard) => {
                card.token = responseCard.card.token;
                card.sessionid = sessionid;
                card.nome = values.cardHolderName;
                card.nascimento = values.cardDtNasc;
                card.cpf = values.cardCpf;
                card.ddd = values.cardDdd;
                card.celular = values.cardCelular;
                card.ass_id = idassinatura;
                console.log(card);
                const urlSendCard = `${properties.server_host}/sendcard`;
                axios.post(urlSendCard, card).then((resposta) => {
                  console.log(resposta.data.message);
                  if (resposta.data.COD === 0) {
                    setStatus({ error: 'Erro na comunicação com operadora de cartão. Tente novamente depois' });
                    setLoad({
                      showLoader: false
                    });
                  } else {
                    navigate('/login', { state: { message: 'Assinatura Realizada com Sucesso' } });
                  }
                });
              },
              error: (responseError) => {
                console.log(responseError);
                if (responseError.error) {
                  const keys = Object.keys(responseError.errors);
                  let msgerros = '';
                  for (let i = 0; i < keys.length; i++) {
                    msgerros += responseError.errors[keys[i]];
                  }
                  setStatus({ error: `Erro nos dados do Cartão: ${msgerros}` });
                } else {
                  setStatus({ error: 'Erro não identificado com dados do Cartão: ' });
                }
                setLoad({
                  showLoader: false
                });
              },
              complete: (responseComplete) => {
                console.log('Complete');
                console.log(responseComplete);
              }
            });
          },
          error: (response) => {
            console.log(response);
            setStatus({ error: 'Erro obtendo bandeira do Cartão. Verifique os dados informados' });
            setLoad({
              showLoader: false
            });
          },
          complete: (response) => {
            console.log('Complete');
            console.log(response);
          }
        });
      }).catch((error) => {
        console.error('Erro Pagseguro getSessionId', error);
        setStatus({ error: 'Erro na comunicação com operadora de cartão, Tente novamente depois' });
        setLoad({
          showLoader: false
        });
      });
    }
  });

  return (
    <>
      <Helmet>
        <title>Cadastro de Apoiador</title>
      </Helmet>
      <Box
        sx={{
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ mb: 3 }}>
              <Typography
                color="textPrimary"
                variant="h2"
              >
                Cadastro de Apoiador - Instituto Santo Agostinho
              </Typography>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                Informe os dados de Cartão de Crédito para concluir o cadastro
              </Typography>
              <Typography
                color="red"
                align="center"
                variant="h4"
              >
                {status ? status.error : ''}
              </Typography>
            </Box>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  error={Boolean(formik.touched.cardNumber && formik.errors.cardNumber)}
                  fullWidth
                  helperText={formik.touched.cardNumber && formik.errors.cardNumber}
                  label="Número do Cartão"
                  margin="normal"
                  name="cardNumber"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  required
                  value={formik.values.cardNumber}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  error={Boolean(formik.touched.cardHolderName && formik.errors.cardHolderName)}
                  fullWidth
                  helperText={formik.touched.cardHolderName && formik.errors.cardHolderName}
                  label="Nome no Cartão"
                  margin="normal"
                  name="cardHolderName"
                  required
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.cardHolderName}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  error={Boolean(formik.touched.cardExpirationMonth && formik.errors.cardExpirationMonth)}
                  fullWidth
                  helperText={formik.touched.cardExpirationMonth && formik.errors.cardExpirationMonth}
                  label="Mês Validade"
                  margin="normal"
                  name="cardExpirationMonth"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={formik.values.cardExpirationMonth}
                  variant="outlined"
                >
                  <option value=""> </option>
                  <option value="01">01</option>
                  <option value="02">02</option>
                  <option value="03">03</option>
                  <option value="04">04</option>
                  <option value="05">05</option>
                  <option value="06">06</option>
                  <option value="07">07</option>
                  <option value="08">08</option>
                  <option value="09">09</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>

                </TextField>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  error={Boolean(formik.touched.cardExpirationYear && formik.errors.cardExpirationYear)}
                  fullWidth
                  helperText={formik.touched.cardExpirationYear && formik.errors.cardExpirationYear}
                  label="Ano Validade"
                  margin="normal"
                  name="cardExpirationYear"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={formik.values.cardExpirationYear}
                  variant="outlined"
                >
                  <option value=""> </option>
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                  <option value="2026">2026</option>
                  <option value="2027">2027</option>
                  <option value="2028">2028</option>
                  <option value="2029">2029</option>
                  <option value="2030">2030</option>

                </TextField>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  error={Boolean(formik.touched.cardCvv && formik.errors.cardCvv)}
                  fullWidth
                  helperText={formik.touched.cardCvv && formik.errors.cardCvv}
                  label="Código de Segurança"
                  margin="normal"
                  name="cardCvv"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  required
                  type="number"
                  value={formik.values.cardCvv}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
              >
                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="h4"
                >
                  Dados do Titular do Cartão:
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  error={Boolean(formik.touched.cardDtNasc && formik.errors.cardDtNasc)}
                  fullWidth
                  helperText={formik.touched.cardDtNasc && formik.errors.cardDtNasc}
                  label="Data Nascimento"
                  margin="normal"
                  name="cardDtNasc"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  required
                  type="date"
                  value={formik.values.cardDtNasc}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  error={Boolean(formik.touched.cardCpf && formik.errors.cardCpf)}
                  fullWidth
                  helperText={formik.touched.cardCpf && formik.errors.cardCpf}
                  label="CPF"
                  margin="normal"
                  name="cardCpf"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  required
                  value={formik.values.cardCpf}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  error={Boolean(formik.touched.cardDdd && formik.errors.cardDdd)}
                  fullWidth
                  helperText={formik.touched.cardDdd && formik.errors.cardDdd}
                  label="DDD"
                  margin="normal"
                  name="cardDdd"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.cardDdd}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  error={Boolean(formik.touched.cardCelular && formik.errors.cardCelular)}
                  fullWidth
                  helperText={formik.touched.cardCelular && formik.errors.cardCelular}
                  label="Celular/Whatsapp"
                  margin="normal"
                  name="cardCelular"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="tel"
                  value={formik.values.cardCelular}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
              >
                <Box
                  sx={{
                    py: 2,
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  {load.showLoader && (
                    <ReactLoading type={loading.type} color={loading.color} />
                  )}
                  <Button
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                    error={Boolean(formik.errors.cardCelular)}
                  >
                    Cadastrar
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Container>
      </Box>
    </>
  );
};

export default CreditCard;
