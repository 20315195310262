import React, { useState } from 'react';
import axios from 'axios';
import ReactLoading from 'react-loading';
import {
  Typography,
  Box,
  Button
} from '@material-ui/core';
import properties from 'src/utils/properties';

const SituacaoAssinaturaAtiva = (props) => {
  const param = props;
  const [loadCancel, setLoadCancel] = useState([]);
  const [status, setStatus] = useState([]);
  let token;
  const config = { headers: { 'x-access-token': '' } };
  const loading = { type: 'bars', color: '#DAA520' };
  const [disable, setDisable] = useState(false);
  const [situacao, setSituacao] = useState('ATIVA');

  function cancelarAssinatura() {
    console.log('Cancelar Assinatura');
    console.log(param);
    setLoadCancel({
      showLoader: true
    });
    const urlUnsubscribe = `${properties.server_host}/unsubscribe/${param.user.id_assinatura}`;
    token = localStorage.getItem('TOKEN_KEY');
    config.headers['x-access-token'] = token;
    axios.post(urlUnsubscribe, {}, config).then((response) => {
      if (response.data.COD === 10) {
        setStatus({ success: 'Assinatura Cancelada Sucesso!' });
        setSituacao('CANCELADA');
        setDisable(true);
        setLoadCancel({
          showLoader: false
        });
      } else {
        setStatus({ success: 'Erro no Cancelamento da Assinatura!' });
        setLoadCancel({
          showLoader: false
        });
      }
    });
  }

  return (
    <>
      <Typography color="blue" variant="h3">
        {situacao}
      </Typography>
      <Typography
        color="red"
        align="center"
        variant="h4"
      >
        {status ? status.success : ''}
      </Typography>
      <Box
        sx={{
          py: 2,
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {loadCancel.showLoader && (
          <ReactLoading type={loading.type} color={loading.color} />
        )}
        <Button
          color="primary"
          size="large"
          variant="contained"
          onClick={cancelarAssinatura}
          disabled={disable}
        >
          Cancelar Assinatura
        </Button>
      </Box>
    </>
  );
};

export default SituacaoAssinaturaAtiva;
