import { useNavigate } from 'react-router-dom';
import {
  Typography,
  Box,
  Button
} from '@material-ui/core';

const SituacaoAssinaturaIncompleta = (props) => {
  const param = props;
  const navigate = useNavigate();

  function concluirAssinatura() {
    console.log('Concluir Assinatura');
    console.log(param);
    navigate('/creditcard', { state: { idassinatura: param.user.id_assinatura } });
  }

  return (
    <>
      <Typography color="orange" variant="h3">
        INCOMPLETA
      </Typography>
      <Box
        sx={{
          py: 2,
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Button
          color="primary"
          size="large"
          variant="contained"
          onClick={concluirAssinatura}
        >
          Concluir Assinatura
        </Button>
      </Box>
    </>
  );
};

export default SituacaoAssinaturaIncompleta;
