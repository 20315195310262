import React, { useState } from 'react';
import ReactLoading from 'react-loading';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import axios from 'axios';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography
} from '@material-ui/core';
import properties from 'src/utils/properties';

const Login = () => {
  const [status, setStatus] = useState([]);
  const [load, setLoad] = useState([]);
  const loading = { type: 'bars', color: '#DAA520' };

  const validSchema = Yup.object().shape({
    email: Yup.string().email('Email incorreto').max(255).required('Email é obrigatório'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: validSchema,
    onSubmit: (values) => {
      setLoad({
        showLoader: true
      });
      const user = {
        email: values.email
      };
      const urlLogin = `${properties.server_host}/sendpasswd`;
      axios.post(urlLogin, user).then((response) => {
        if (response.status === 200) {
          if (response.data.message) {
            setStatus({ success: response.data.message });
            setLoad({
              showLoader: false
            });
          }
        }
      }).catch((erro) => {
        console.error('entrou erro', erro);
        if (erro.response.status === 500) {
          setStatus({ success: erro.response.data.message });
          setLoad({
            showLoader: false
          });
        } else {
          setStatus({ success: 'Erro Buscando Usuário' });
          setLoad({
            showLoader: false
          });
        }
      });
    }
  });

  return (
    <>
      <Helmet>
        <title>Login de Apoiador</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ mb: 3 }}>
              <Typography
                color="textPrimary"
                variant="h2"
              >
                Recuperar Senha
              </Typography>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                Informe o email cadastrado para recuperar a Senha de sua conta
              </Typography>
              <Typography
                color="red"
                align="center"
                variant="h4"
              >
                {status ? status.success : ''}
              </Typography>
            </Box>
            <TextField
              error={Boolean(formik.touched.email && formik.errors.email)}
              fullWidth
              helperText={formik.touched.email && formik.errors.email}
              label="Email"
              margin="normal"
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="email"
              value={formik.values.email}
              variant="outlined"
            />
            <Box
              sx={{
                py: 2,
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              {load.showLoader && (
                <ReactLoading type={loading.type} color={loading.color} />
              )}
              <Button
                color="primary"
                size="large"
                type="submit"
                variant="contained"
              >
                RECUPERAR SENHA
              </Button>
            </Box>
            <Typography
              color="textSecondary"
              variant="body1"
            >
              Para Fazer Login
              {' '}
              <Link
                component={RouterLink}
                to="/login"
                variant="h6"
              >
                Clique aqui
              </Link>
            </Typography>
          </form>
        </Container>
      </Box>
    </>
  );
};

export default Login;
