import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import axios from 'axios';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  RadioGroup,
  Radio,
  Typography,
  Grid,
  FormLabel
} from '@material-ui/core';
import properties from 'src/utils/properties';

const Register = () => {
  const navigate = useNavigate();
  const [estados, setEstados] = useState([]);
  const [planos, setPlanos] = useState([]);
  const [status, setStatus] = useState([]);
  const [load, setLoad] = useState([]);
  const loading = { type: 'bars', color: '#DAA520' };

  useEffect(() => {
    const urlUf = `${properties.server_host}/estados`;
    axios.get(urlUf).then((response) => {
      setEstados(response.data);
    });
  }, []);

  useEffect(() => {
    const urlPlanos = `${properties.server_host}/planos`;
    axios.get(urlPlanos).then((response) => {
      console.log(response.data);
      setPlanos(response.data);
    });
  }, []);

  const validationSchemaConst = Yup.object().shape({
    txtNome: Yup.string().max(50, 'Tamanho máximo do Nome é de 50 caracteres').required('Nome é Obrigatório'),
    dataNascimento: Yup.date().required('Data Nascimento é obrigatório'),
    txtCPF: Yup.string().max(11).required('CPF é obrigatório').max(11, 'Tamanho máximo de 11 dígitos. Não inserir ponto (.), traço (-) ou espaço'),
    email: Yup.string().max(60, 'Tamanho máximo do email é de 60 caracteres').email('Email incorreto').max(255)
      .required('Email é obrigatório'),
    numDdd: Yup.string().max(2, 'Apenas 2 dígitos. Exemplo: 48'),
    txtCelular: Yup.string().min(7).max(9, 'Tamanho máximo de 9 dígitos.  Não inserir ponto (.), traço (-) ou espaço'),
    txtRua: Yup.string().max(80).required('Rua é obrigatório'),
    txtNumero: Yup.string().max(20),
    txtComplemento: Yup.string().max(40),
    txtBairro: Yup.string().max(60),
    txtCidade: Yup.string().max(60).required('Cidade é Obrigatório'),
    txtCEP: Yup.string().max(8, 'Máximo 8 caracteres. Não inserir ponto (.), traço (-) ou espaço').required('CEP é Obrigatório'),
    cmbUF: Yup.string().required('Estado é Obrigatório'),
    cmbPlano: Yup.string().required('Escolha um Plano'),
    password: Yup.string().min(5, 'Senha deve ter no mínimo 5 dígitos').required('Senha é obrigatório'),
    passwordRepeat: Yup.string().required('password is required').oneOf([Yup.ref('password'), null], 'Senha não confere'),
    policy: Yup.boolean().oneOf([true], 'Este campo deve ser marcado'),
    sexo: Yup.string().required('Informe o sexo')

  });

  const formik = useFormik({
    initialValues: {
      txtNome: '',
      dataNascimento: '',
      sexo: '',
      txtCPF: '',
      numDdd: '',
      email: '',
      txtCelular: '',
      txtRua: '',
      txtNumero: '',
      txtComplemento: '',
      txtBairro: '',
      txtCidade: '',
      txtCEP: '',
      cmbUF: '',
      cmbPlano: '',
      password: '',
      passwordRepeat: '',
      policy: false
    },
    validationSchema: validationSchemaConst,
    onSubmit: (values) => {
      console.log('entrou submit');
      setLoad({
        showLoader: true
      });
      console.log(values);
      const clientHash = window.PagSeguroDirectPayment.getSenderHash();
      console.log(clientHash);
      const urlCad = `${properties.server_host}/cadastro`;
      axios.post(urlCad, { values, clientHash }).then((response) => {
        if (response.data.COD === 0) {
          if (response.data.message) {
            setStatus({ success: response.data.message });
          } else {
            setStatus({ success: 'Erro Não identificado. Tente novamente depois ou entre em contato com nosso suporte.' });
          }
          setLoad({
            showLoader: false
          });
        } else {
          setLoad({
            showLoader: false
          });
          navigate('/creditcard', { state: { idassinatura: response.data.COD } });
        }
      });
    }
  });

  return (
    <>
      <Helmet>
        <title>Cadastro de Apoiador</title>
      </Helmet>
      <Box
        sx={{
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ mb: 3 }}>
              <Typography
                color="textPrimary"
                variant="h2"
              >
                Cadastro de Apoiador - Instituto Santo Agostinho
              </Typography>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                Informe seus dados para criar sua conta
              </Typography>
              <Typography
                color="red"
                align="center"
                variant="h4"
              >
                {status ? status.success : ''}
              </Typography>
            </Box>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={12}
                xs={12}
              >
                <TextField
                  error={Boolean(formik.touched.txtNome && formik.errors.txtNome)}
                  fullWidth
                  helperText={formik.touched.txtNome && formik.errors.txtNome}
                  label="Nome"
                  margin="normal"
                  name="txtNome"
                  required
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.txtNome}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  error={Boolean(formik.touched.dataNascimento && formik.errors.dataNascimento)}
                  fullWidth
                  helperText={formik.touched.dataNascimento && formik.errors.dataNascimento}
                  label="Data Nascimento"
                  margin="normal"
                  name="dataNascimento"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  required
                  type="date"
                  value={formik.values.dataNascimento}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    ml: -1
                  }}
                >
                  <FormLabel component="fieldset">
                    <FormLabel component="legend">Sexo</FormLabel>
                    <RadioGroup
                      error={Boolean(formik.errors.sexo)}
                      helperText={formik.touched.sexo && formik.errors.sexo}
                      margin="normal"
                      name="sexo"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      required
                      radio
                      variant="outlined"
                    >
                      <Grid
                        container
                        spacing={3}
                      >
                        <Grid
                          item
                          md={6}
                          xs={12}
                        >
                          <FormLabel component="legend">Masculino</FormLabel>
                          <Radio
                            color="primary"
                            value="M"
                          />
                        </Grid>
                        <Grid
                          item
                          md={6}
                          xs={12}
                        >
                          <FormLabel component="legend">Feminino</FormLabel>
                          <Radio
                            color="primary"
                            value="F"
                          />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </FormLabel>
                </Box>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  error={Boolean(formik.touched.txtCPF && formik.errors.txtCPF)}
                  fullWidth
                  helperText={formik.touched.txtCPF && formik.errors.txtCPF}
                  label="CPF"
                  margin="normal"
                  name="txtCPF"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  required
                  value={formik.values.txtCPF}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  error={Boolean(formik.touched.email && formik.errors.email)}
                  fullWidth
                  helperText={formik.touched.email && formik.errors.email}
                  label="Email"
                  margin="normal"
                  name="email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  required
                  type="email"
                  value={formik.values.email}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  error={Boolean(formik.touched.numDdd && formik.errors.numDdd)}
                  fullWidth
                  helperText={formik.touched.numDdd && formik.errors.numDdd}
                  label="DDD"
                  margin="normal"
                  name="numDdd"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.numDdd}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  error={Boolean(formik.touched.txtCelular && formik.errors.txtCelular)}
                  fullWidth
                  helperText={formik.touched.txtCelular && formik.errors.txtCelular}
                  label="Celular/Whatsapp"
                  margin="normal"
                  name="txtCelular"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="tel"
                  value={formik.values.txtCelular}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  error={Boolean(formik.touched.txtRua && formik.errors.txtRua)}
                  fullWidth
                  helperText={formik.touched.txtRua && formik.errors.txtRua}
                  label="Rua"
                  margin="normal"
                  name="txtRua"
                  required
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.txtRua}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  error={Boolean(formik.touched.txtNumero && formik.errors.txtNumero)}
                  fullWidth
                  helperText={formik.touched.txtNumero && formik.errors.txtNumero}
                  label="Número"
                  margin="normal"
                  name="txtNumero"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.txtNumero}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  error={Boolean(formik.touched.txtComplemento && formik.errors.txtComplemento)}
                  fullWidth
                  helperText={formik.touched.txtComplemento && formik.errors.txtComplemento}
                  label="Complemento"
                  margin="normal"
                  name="txtComplemento"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.txtComplemento}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  error={Boolean(formik.touched.txtBairro && formik.errors.txtBairro)}
                  fullWidth
                  helperText={formik.touched.txtBairro && formik.errors.txtBairro}
                  label="Bairro"
                  margin="normal"
                  name="txtBairro"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.txtBairro}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  error={Boolean(formik.touched.txtCidade && formik.errors.txtCidade)}
                  fullWidth
                  helperText={formik.touched.txtCidade && formik.errors.txtCidade}
                  label="Cidade"
                  margin="normal"
                  name="txtCidade"
                  required
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.txtCidade}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  error={Boolean(formik.touched.txtCEP && formik.errors.txtCEP)}
                  fullWidth
                  helperText={formik.touched.txtCEP && formik.errors.txtCEP}
                  label="CEP"
                  margin="normal"
                  name="txtCEP"
                  required
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.txtCEP}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
              >
                <TextField
                  error={Boolean(formik.touched.cmbUF && formik.errors.cmbUF)}
                  fullWidth
                  helperText={formik.touched.cmbUF && formik.errors.cmbUF}
                  label="Estado"
                  margin="normal"
                  name="cmbUF"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={formik.values.cmbUF}
                  variant="outlined"
                >
                  <option value=""> </option>
                  {estados.map((estado) => (<option key={estado.sigla} value={estado.sigla}>{estado.nome}</option>))}
                </TextField>
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
              >
                <TextField
                  error={Boolean(formik.touched.cmbPlano && formik.errors.cmbPlano)}
                  fullWidth
                  helperText={formik.touched.cmbPlano && formik.errors.cmbPlano}
                  label="Plano de Assinatura"
                  margin="normal"
                  name="cmbPlano"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={formik.values.cmbPlano}
                  variant="outlined"
                >
                  <option value=""> </option>
                  {planos.map((plano) => (
                    <option key={plano.codigo} value={plano.id}>
                      {plano.descricao}
                      . R$
                      {plano.valor}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  error={Boolean(formik.touched.password && formik.errors.password)}
                  fullWidth
                  helperText={formik.touched.password && formik.errors.password}
                  label="Senha"
                  margin="normal"
                  name="password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  required
                  type="password"
                  value={formik.values.password}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  error={Boolean(formik.touched.passwordRepeat && formik.errors.passwordRepeat)}
                  fullWidth
                  helperText={formik.touched.passwordRepeat && formik.errors.passwordRepeat}
                  label="Repetir Senha"
                  margin="normal"
                  name="passwordRepeat"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  required
                  type="password"
                  value={formik.values.passwordRepeat}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    ml: -1
                  }}
                >
                  <Checkbox
                    checked={formik.values.policy}
                    name="policy"
                    onChange={formik.handleChange}
                  />
                  <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                    Eu li os termos e concordo
                    {' '}
                    <Link
                      color="primary"
                      component={RouterLink}
                      to="#"
                      underline="always"
                      variant="h6"
                    >
                      Termos e Condições
                    </Link>
                  </Typography>
                </Box>
                {Boolean(formik.touched.policy && formik.errors.policy) && (
                  <FormHelperText error>
                    {formik.errors.policy}
                  </FormHelperText>
                )}
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
              >
                <Box
                  sx={{
                    py: 2,
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  {load.showLoader && (
                    <ReactLoading type={loading.type} color={loading.color} />
                  )}
                  <Button
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Prosseguir
                  </Button>
                </Box>
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
              >
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Já tenho Cadastro
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/login"
                    variant="h6"
                  >
                    Login
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </form>
        </Container>
      </Box>
    </>
  );
};

export default Register;
