import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import axios from 'axios';
import ReactLoading from 'react-loading';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Typography
} from '@material-ui/core';
import properties from 'src/utils/properties';

const SettingsPassword = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState([]);
  const [load, setLoad] = useState([]);
  const params = useLocation().search;
  const loading = { type: 'bars', color: '#DAA520' };
  const email = new URLSearchParams(params).get('account');
  let urlVerificaPasswd;
  const [disable, setDisable] = useState([]);
  const [idSolicita, setIdSolicita] = useState([]);

  function verificaUpdateSenha() {
    urlVerificaPasswd = `${properties.server_host}/getnewpasswd/${email}`;
    axios.get(urlVerificaPasswd).then((response) => {
      setIdSolicita(response.data.solicita.id);
      setDisable(false);
    }).catch((erro) => {
      console.error('entrou erro', erro);
      if (erro.response.status === 500) {
        setStatus({ success: erro.response.data.message });
        setDisable(true);
      } else {
        setStatus({ success: 'Erro Processando Solicitação' });
        setDisable(true);
      }
    });
  }

  useEffect(() => {
    console.log(email);
    verificaUpdateSenha();
  }, []);

  const validSchema = Yup.object().shape({
    password: Yup.string().min(5, 'Senha deve ter no mínimo 5 dígitos').required('Senha é obrigatório'),
    passwordRepeat: Yup.string().required('password is required').oneOf([Yup.ref('password'), null], 'Senha não confere')
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordRepeat: ''
    },
    validationSchema: validSchema,
    onSubmit: (values) => {
      setLoad({
        showLoader: true
      });
      const param = values;
      const urlUpdtPasswd = `${properties.server_host}/updtpasswd/${idSolicita}`;
      axios.post(urlUpdtPasswd, param).then((response) => {
        console.log(response);
        console.log(response.data.message);
        if (response.data.COD === 10) {
          setStatus({ error: response.data.message });
          setLoad({
            showLoader: false
          });
          navigate('/login', { state: { message: response.data.message } });
        }
      }).catch((erro) => {
        console.error('entrou erro', erro);
        if (erro.response.status === 500) {
          setStatus({ success: erro.response.data.message });
          setLoad({
            showLoader: false
          });
        } else {
          setStatus({ success: 'Erro Buscando Usuário' });
          setLoad({
            showLoader: false
          });
        }
      });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Card>
        <CardHeader
          subheader="Informe Nova Senha para acessar sua conta"
          title="Cadastre Nova Senha"
        />
        <Divider />
        <CardContent>
          <Typography
            color="red"
            align="center"
            variant="h4"
          >
            {status ? status.success : ''}
          </Typography>
          <TextField
            error={Boolean(formik.touched.password && formik.errors.password)}
            fullWidth
            helperText={formik.touched.password && formik.errors.password}
            label="Senha"
            margin="normal"
            name="password"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            required
            type="password"
            value={formik.values.password}
            variant="outlined"
            disabled={disable}
          />
          <TextField
            error={Boolean(formik.touched.passwordRepeat && formik.errors.passwordRepeat)}
            fullWidth
            helperText={formik.touched.passwordRepeat && formik.errors.passwordRepeat}
            label="Repetir Senha"
            margin="normal"
            name="passwordRepeat"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            required
            type="password"
            value={formik.values.passwordRepeat}
            variant="outlined"
            disabled={disable}
          />
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          {load.showLoader && (
            <ReactLoading type={loading.type} color={loading.color} />
          )}
          <Button
            color="primary"
            variant="contained"
            disabled={disable}
            type="submit"
          >
            Atualizar Senha
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default SettingsPassword;
