import {
  Typography
} from '@material-ui/core';

function SituacaoAssinaturaCancelada() {
  return (
    <Typography color="red" variant="h3">
      CANCELADA
    </Typography>
  );
}

export default SituacaoAssinaturaCancelada;
